import { useMemo } from 'react';

import { escapeRegExp } from '../../../../../utils/escapeRegExp';

type useFilteredGroupsProps = {
  groups: TCategories;
  search: string;
};

export const useFilteredGroups = ({
  groups,
  search,
}: useFilteredGroupsProps) => {
  const filteredGroups = useMemo(() => {
    if (search) {
      const filtered = groups
        .map((category) => ({
          ...category,
          parts: Object.keys(category.parts).reduce((acc, key) => {
            const finalArray = category.parts[key].filter((part) => {
              return part.name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .match(
                  new RegExp(
                    escapeRegExp(
                      search.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                    ),
                    'i'
                  )
                );
            });

            if (finalArray.length === 0) {
              return acc;
            }

            return {
              [key]: finalArray,
              ...acc,
            };
          }, {}),
        }))
        .filter(
          (category) =>
            Object.values(category.parts).filter((p) => {
              return p.length > 0;
            }).length > 0
        );

      return filtered;
    }

    return groups;
  }, [groups, search]);

  return filteredGroups;
};
