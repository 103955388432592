import { useQuery } from '@tanstack/react-query';
import { atom } from 'jotai';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

import { fetchByKba, fetchByPlateOrVin } from '../../services/vehicles';
import getUserSavedCars from '../../utils/react-query/query-options/get-user-saved-cars';
import useToken from '../use-token';

const { publicRuntimeConfig } = getConfig();
const regularExpression = /^\w{4}-\w{3}$/;

export const loadVehicles = async ({ locale, slug, isKba }) => {
  if (isKba) {
    if (slug.match(regularExpression)) {
      const [hsn, tsn] = slug.split('-');
      return fetchByKba({ locale, hsn, tsn });
    }
    return;
  }

  return fetchByPlateOrVin({ locale, slug });
};

/*export const brandAtom = atom(
  publicRuntimeConfig.isFeatureBrandEnabled
    ? { value: publicRuntimeConfig.brand }
    : null
);*/

export const brandAtom = atom(null);

export const modelAtom = atom<TModel | { slug: string } | null>(null);
export const versionAtom = atom<TVersion | null>(null);

export const searchByPlateOrKBAAtom = atom<boolean>(false);

export const slugAtom = atom((get) => {
  const model = get<TModel | null>(modelAtom);
  const version = get(versionAtom);

  if (model && !model.have_versions) {
    return model.slug;
  }

  if (version) {
    return version?.value;
  }

  return null;
});

export const setBrandAtom = atom(null, (get, set, value) =>
  set(brandAtom, value)
);
export const setModelAtom = atom(null, (get, set, value) =>
  set(modelAtom, value)
);
export const setversionAtom = atom(null, (get, set, value) =>
  set(versionAtom, value)
);

export const resetPickerSelection = atom(null, (get, set) => {
  set(
    brandAtom,
    publicRuntimeConfig.isFeatureBrandEnabled
      ? { value: publicRuntimeConfig.brand }
      : null
  );
  set(modelAtom, null);
  set(versionAtom, null);
});

export const sendEvent = (plate: string) => {
  window.dataLayer.push({
    event: 'plateSearch',
    plateNumber: plate, // car plate
  });
};

export const useGetVehicles = () => {
  const { locale } = useRouter();
  const csrftoken = useToken();

  const { data: vehicles, isLoading } = useQuery(
    ...getUserSavedCars({ locale, csrftoken }),
    {
      refetchOnMount: 'always',
    }
  );
  return { vehicles, isLoading };
};
