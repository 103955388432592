import { ParsedUrlQuery } from 'querystring';

import { resolveHref } from 'next/dist/client/resolve-href';
import { useRouter } from 'next/router';

import { locales } from '../../../../config/i18n';
import routesDA from '../../../../locales/da/routes.json';
import routesDE from '../../../../locales/de/routes.json';
import routesEN from '../../../../locales/en/routes.json';
import routesES from '../../../../locales/es/routes.json';
import routesFR from '../../../../locales/fr/routes.json';
import routesIT from '../../../../locales/it/routes.json';
import routesNB from '../../../../locales/nb/routes.json';
import routesNL from '../../../../locales/nl/routes.json';
import routesPT from '../../../../locales/pt/routes.json';
import { originalRoutes } from '../../../hooks/use-link-props';

const replaceParams = (
  nextRouter: ReturnType<typeof useRouter>,
  route: string,
  params?: { [key: string]: string }
) => {
  const router = nextRouter;
  return resolveHref(
    router,
    {
      pathname: route,
      query: params,
    },
    true
  )[1] as string;
};

const removeTrailingSlash = (route: string) => {
  if (route[route.length - 1] === '/') {
    return route.substring(0, route.length - 1);
  }

  return route;
};

const useHreflang = ({
  locale,
  route,
  params,
  translatedSlugs,
}: {
  locale: string;
  route: keyof typeof originalRoutes;
  params?: ParsedUrlQuery;
  translatedSlugs?: {
    da: { slug_type: string; slug_origin: string; slug_trans: string }[];
    de: { slug_type: string; slug_origin: string; slug_trans: string }[];
    en: { slug_type: string; slug_origin: string; slug_trans: string }[];
    es: { slug_type: string; slug_origin: string; slug_trans: string }[];
    fr: { slug_type: string; slug_origin: string; slug_trans: string }[];
    it: { slug_type: string; slug_origin: string; slug_trans: string }[];
    nb: { slug_type: string; slug_origin: string; slug_trans: string }[];
    nl: { slug_type: string; slug_origin: string; slug_trans: string }[];
    pt: { slug_type: string; slug_origin: string; slug_trans: string }[];
  };
}) => {
  const router = useRouter();
  const routes = {
    'x-default': routesEN[route],
    da: routesDA[route],
    de: routesDE[route],
    en: routesEN[route],
    'en-gb': routesEN[route],
    es: routesES[route],
    fr: routesFR[route],
    it: routesIT[route],
    nb: routesNB[route],
    nl: routesNL[route],
    pt: routesPT[route],
  };

  const finalLocales: Array<string> = [...locales, 'en-gb'];

  if (process.env.NEXT_PUBLIC_HOST?.includes('.co.uk')) {
    finalLocales.push('es', 'pt', 'fr', 'it', 'nb', 'da', 'de', 'nl');
  }

  const links = finalLocales.map((locale) => {
    const localefinal = locale === 'en-gb' ? 'en' : locale;
    const domain =
      locale === 'en-gb'
        ? process.env.NEXT_PUBLIC_HOST?.includes('dev')
          ? 'https://dev.b-parts.co.uk'
          : 'https://www.b-parts.co.uk'
        : process.env.NEXT_PUBLIC_HOST?.includes('dev')
        ? 'https://dev.b-parts.com'
        : 'https://www.b-parts.com';

    return (
      <link
        key={locale}
        rel="alternate"
        hrefLang={locale}
        href={`${domain}${removeTrailingSlash(
          replaceParams(
            router,
            // @ts-ignore
            routes[localefinal],
            params
              ? Object.entries(params).reduce((final, [query, slug]) => {
                  if (
                    translatedSlugs &&
                    // @ts-ignore
                    translatedSlugs[localefinal] &&
                    // @ts-ignore
                    translatedSlugs[localefinal].find(
                      (e) => e.slug_origin === slug
                    )
                  ) {
                    // @ts-ignore
                    const newSlug = translatedSlugs[localefinal].find(
                      (e) => e.slug_origin === slug
                    );

                    return { ...final, [query]: newSlug?.slug_trans ?? slug };
                  }

                  return { ...final, [query]: slug };
                }, {})
              : {}
          )
        )}`}
      />
    );
  });

  links.push(
    <link
      key="x-default"
      rel="alternate"
      hrefLang="x-default"
      href={`${
        process.env.NEXT_PUBLIC_HOST?.includes('dev')
          ? 'https://dev.b-parts.com'
          : 'https://www.b-parts.com'
      }${removeTrailingSlash(
        replaceParams(
          router,
          routes['en'],
          params
            ? Object.entries(params).reduce((final, [query, slug]) => {
                if (
                  translatedSlugs &&
                  translatedSlugs['en'] &&
                  translatedSlugs['en'].find((e) => e.slug_origin === slug)
                ) {
                  const newSlug = translatedSlugs['en'].find(
                    (e) => e.slug_origin === slug
                  );

                  return { ...final, [query]: newSlug?.slug_trans ?? slug };
                }

                return { ...final, [query]: slug };
              }, {})
            : {}
        )
      )}`}
    />
  );

  return (
    <>
      <link
        rel="canonical"
        href={`${process.env.NEXT_PUBLIC_HOST}${removeTrailingSlash(
          replaceParams(
            router,
            // @ts-ignore
            routes[locale],
            params
          )
        )}`}
      ></link>
      {links}
    </>
  );
};

export default useHreflang;
