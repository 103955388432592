type TgenerateLink = {
  slug: TVehicleSelection;
  partSlug: string;
  categorySlug: string;
  getLink: any;
  reference?: string | undefined;
};

const generateLink = ({
  getLink,
  slug,
  partSlug,
  categorySlug,
  reference,
}: TgenerateLink) => {
  if (reference) {
    return getLink('reference', {
      reference: reference!,
      ...(categorySlug &&
        partSlug && {
          category: categorySlug,
          subCategory: partSlug,
        }),
      ...(slug && { vehicle: slug }),
    });
  } else {
    return getLink(
      slug && partSlug && categorySlug ? 'products' : 'reference',
      {
        ...(partSlug && { subCategory: partSlug }),
        ...(categorySlug && { category: categorySlug }),
        ...(slug && { vehicle: slug }),
      }
    );
  }
};

export default generateLink;
