/* eslint-disable @next/next/no-img-element */
import cx from 'classnames';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FunctionComponent, useMemo } from 'react';

import { useAppContext } from '../../providers/app-context';
import BPImage from '../../ui/v2/image';
import IconCheckPicker from '../../ui/icons/check-picker';
import Trustpilot from '../../modules/trustpilot';

const LazyPicker = dynamic(
  () => import(/* webpackPreload: true */ '../../modules/home-picker'),
  {
    ssr: false,
  }
);

const Banner: FunctionComponent = () => {
  const { shippingLocation, isKBA, isPlate, businessValues } = useAppContext();
  const { t } = useTranslation();
  const { locale = 'en' } = useRouter();

  const subTitle = [
    {
      i18nKey: 'home:banner.bullets.stock',
      values: {
        totalStockAvailable: businessValues.totalStockAvailable.defaultValue,
      },
    },
    {
      i18nKey: 'home:banner.bullets.shipping',
      values: {},
    },
    {
      i18nKey: 'home:banner.bullets.warranty',
      values: {},
    },
  ];

  const shippingLocationCountry = useMemo(() => {
    switch (shippingLocation) {
      case 1:
      case 2:
      case 3:
        return t('home:banner.countries.portugal');
      case 4:
      case 5:
      case 6:
        return t('home:banner.countries.spain');
      case 11:
        return t('home:banner.countries.france');
      case 13:
        return t('home:banner.countries.germany');
      case 23:
        return t('home:banner.countries.italy');
      case 25:
        return t('home:banner.countries.belgium');
      case 26:
        return t('home:banner.countries.austria');
      default:
        switch (locale) {
          case 'pt':
            return t('home:banner.countries.portugal');
          case 'es':
            return t('home:banner.countries.spain');
          case 'fr':
            return t('home:banner.countries.france');
          case 'de':
            return t('home:banner.countries.germany');
          case 'it':
            return t('home:banner.countries.italy');
          case 'nl':
            return t('home:banner.countries.belgium');
          case 'at':
            return t('home:banner.countries.austria');
          case 'en':
            return t('home:banner.countries.european');
          default:
            return t('home:banner.countries.european');
        }
    }
  }, [locale, shippingLocation, t]);

  const boxPlateHeight = useMemo(() => {
    if (isKBA || isPlate) {
      return 'h-fit';
    } else {
      return 'h-fit';
    }
  }, [isKBA, isPlate]);

  return (
    <section className={cx('relative ', 'text-white', 'py-6 px-6 lg:py-8')}>
      <BPImage
        src={'/svg/banner-bg.svg'}
        alt="image"
        fill={true}
        sizes="100%"
        className="object-cover object-right-bottom"
        quality={100}
      />
      <div
        className={cx(
          'lg:container mx-auto',
          'relative',
          'z-10',
          'flex flex-col gap-3 lg:gap-8'
        )}
      >
        <div className="lg:self-center flex flex-col items-center lg:gap-4">
          <h1
            className={cx(
              'text-[28px] leading-[32px] lg:text-[54px] lg:leading-[60px] font-extrabold',
              'text-center'
            )}
          >
            <Trans
              i18nKey="home:banner.title"
              components={{ b: <b /> }}
              values={{ country: shippingLocationCountry }}
            />
          </h1>
          <ul className={cx('hidden', 'lg:flex gap-6')}>
            {subTitle.map((element, index) => {
              return (
                <li className="flex gap-2 items-center" key={index}>
                  <IconCheckPicker
                    className={cx('w-3.5 h-3.5 stroke-2 text-white')}
                  />
                  <p className="text-lg leading-6 font-bold">
                    {t(element.i18nKey, element.values)}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={cx('w-full lg:w-1/4 mx-auto', boxPlateHeight)}>
          <LazyPicker />
        </div>
        <Trustpilot className="text-white" backgroundColor="none"></Trustpilot>
      </div>
    </section>
  );
};

export default Banner;
