import cx from 'classnames';
import {
  forwardRef,
  ComponentProps,
  ReactNode,
  useRef,
  useState,
  ChangeEvent,
  FocusEvent,
  useEffect,
} from 'react';

import { useDisableFocus } from '../../../../providers/disable-focus';
import Button from '../../../button';
import Cheverlon from '../../../icons/cheverlon';
import FilterIcon from '../../../icons/filter';
import { ErrorLine } from '../errors';

import classes from './text.module.css';
import IconCloseRounded from '../../../icons/close-rounded';

type TextInputProps = {
  as?: 'input' | 'textarea';
  hideLabel?: boolean;
  label: string;
  labelBehaviour?: 'default' | 'animated';
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  variant?: 'filter' | 'select' | 'search' | 'default';
  border?: boolean;
  error_messages?: Array<string>;
  inputExtraCss?: string;
  clearButton?: boolean;
  onClear?: () => void;
  focusBg?: boolean;
  showExtraIcon?: boolean;
  disabled?: boolean;
  scrollToTop?: boolean;
  backButtonOnFocus?: boolean;
  fullWidth?: boolean;
  inputPattern?: string;
  idToFocusOnMax?: string;
  idToFocusOnMin?: string;
  inputMaxlength?: number;
} & ComponentProps<'input'>;

const getIcon = (variant: TextInputProps['variant'], styles?: string) => {
  switch (variant) {
    case 'search':
      return <FilterIcon className={styles} />;
    case 'filter':
      return <FilterIcon className={styles} />;
    case 'select':
      return <Cheverlon className={styles} />;

    default:
      break;
  }
};

const getVariantCSS = (
  variant: TextInputProps['labelBehaviour'],
  inputFocused?: any
) => {
  switch (variant) {
    case 'animated':
      /*To be implemented 
      if (backButtonOnFocus && inputFocused) {
        return cx(
          classes.animatedLabel,
          'absolute',
          '!left-[48px]',
          'text-neutral-80'
        );
      }*/
      return cx(classes.animatedLabel, 'absolute', 'text-neutral-80');
    case 'default':
    default:
      return cx('absolute', 'text-xs', 'font-bold', 'top-0 max-lg:top-1');
  }
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      as: As = 'input',
      hideLabel = false,
      labelBehaviour = 'default',
      id,
      label,
      leftIcon,
      rightIcon,
      variant = 'default',
      border = false,
      error_messages = [],
      onChange: onChangeCallback,
      onFocus: onFocusCallback,
      onBlur: onBlurCallback,
      placeholder: originalPlaceholder,
      inputExtraCss,
      clearButton,
      onClear,
      focusBg = false,
      showExtraIcon = false,
      disabled = false,
      scrollToTop = false,
      backButtonOnFocus = false,
      fullWidth = false,
      inputPattern = undefined,
      inputMaxlength = undefined,
      idToFocusOnMin = undefined,
      idToFocusOnMax = undefined,
      ...props
    },
    ref
  ) => {
    const disableFocus = useDisableFocus();
    const innerRef = useRef<HTMLInputElement>(null);
    const labelRef = useRef<HTMLLabelElement>(null);
    const [showErrors, setShowErrors] = useState<boolean>(
      !!error_messages.length
    );
    const [isInputWithText, setIsInputWithText] = useState<boolean>(false);
    const targerRef = ref || innerRef;

    const onClearButton = () => {
      //setIsInputWithText(false);
      if (onClear) {
        onClear();
      }
    };

    const customOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      setIsInputWithText(true);
      if (targerRef.current?.value === '') {
        setIsInputWithText(false);
      }
      if (error_messages.length) setShowErrors(false);

      if (onChangeCallback) {
        onChangeCallback(e);
      }
    };

    const customOnFocus = (e: FocusEvent<HTMLInputElement>) => {
      targerRef.current?.focus();
      labelRef.current?.classList.add(classes.focus);

      if (onFocusCallback) {
        onFocusCallback(e);
      }
    };

    const customOnBlur = (e: FocusEvent<HTMLInputElement>) => {
      if (!targerRef.current?.value && !props.value) {
        labelRef.current?.classList.remove(classes.focus);
      }

      setIsInputWithText(false);
      props.value = '';

      if (onBlurCallback) {
        onBlurCallback(e);
      }
    };

    return (
      <div
        className={cx(
          'relative',
          'w-full h-12 items-end',
          'flex flex-row gap-1',
          ...(border
            ? [
                'border-b',
                showErrors && error_messages.length
                  ? 'border-red-100'
                  : disabled
                  ? 'border-neutral-50'
                  : 'border-neutral-80 group-focus:border-brand-100 group-focus-within:border-brand-100',
              ]
            : [])
        )}
      >
        <div
          className={cx(
            'h-12 items-end',
            {
              'w-full grid grid-cols-[1fr_48px]': clearButton && !showExtraIcon,
              'w-full grid grid-cols-[1fr_48px_48px]':
                clearButton && showExtraIcon,
            },
            {
              'w-full': fullWidth,
            }
          )}
        >
          <div
            className={cx(
              'pt-5 pb-2 max-lg:pb-1 max-lg:truncate pl-4 max-lg:px-4'
            )}
          >
            {label && !hideLabel && (
              <label
                ref={labelRef}
                htmlFor={id}
                className={cx(
                  'block',
                  'cursor-text z-[1]',
                  'group-focus-within:text-brand-100 group-focus:text-brand-100',
                  { [classes.focus]: !!props.value },
                  getVariantCSS(labelBehaviour),
                  {
                    'text-neutral-50': disabled,
                  }
                )}
                onClick={() => targerRef.current?.focus()}
              >
                {label}
              </label>
            )}
            <div
              className={cx(
                { flex: !clearButton },
                { 'group-focus-within:bg-brand-10': focusBg },
                'grow',
                classes.container
              )}
              onClick={() => {
                if (disabled) return;
                // TODO - Property 'current' does not exist on type '(instance: HTMLInputElement | null) => void'
                targerRef.current?.focus();
              }}
            >
              <As
                className={cx(
                  'flex',
                  'text-base',
                  'leading-5',
                  'min-w-full',
                  'outline-none',
                  'bg-transparent',
                  'disabled:bg-neutral-10',
                  'rounded-none',
                  {
                    'placeholder-red-100': showErrors && error_messages.length,
                  },
                  {
                    'placeholder-neutral-80 group-focus-within:placeholder-brand-100':
                      !showErrors || !error_messages.length,
                    '!text-neutral-50 !placeholder-neutral-50': disabled,
                  },
                  inputExtraCss,
                  classes.inputField,
                  'p-0'
                )}
                id={id}
                tabIndex={disableFocus ? -1 : 0}
                ref={targerRef}
                aria-label={label}
                disabled={disabled}
                {...props}
                onChange={customOnChange}
                onFocus={customOnFocus}
                onBlur={customOnBlur}
                placeholder={
                  originalPlaceholder || (hideLabel && label) || undefined
                }
                maxlength={inputMaxlength}
                pattern={inputPattern}
                onInput={(e) => {
                  const isMaxlength =
                    e.currentTarget.value.length.toString() ===
                    e.currentTarget.getAttribute('maxlength');
                  if (isMaxlength && idToFocusOnMax) {
                    document.getElementById(idToFocusOnMax)?.focus();
                  }
                  const isMinLength = e.currentTarget.value.length === 0;
                  if (isMinLength && idToFocusOnMin) {
                    document.getElementById(idToFocusOnMin)?.focus();
                  }
                }}
              />
            </div>
          </div>
          <div>
            {isInputWithText || props.value ? (
              clearButton && (
                <Button
                  type="button"
                  onClick={onClearButton}
                  className={cx('')}
                >
                  <IconCloseRounded
                    className={cx(
                      'h-4',
                      'w-4',
                      'group-focus-within:text-neutral-80',
                      {
                        'text-neutral-50 ': disabled,
                      }
                    )}
                  ></IconCloseRounded>
                </Button>
              )
            ) : (
              <div className={cx('h-12 w-12')}></div>
            )}
          </div>
          {showExtraIcon && (
            <div
              className={cx('flex justify-self-center self-center', {
                'text-neutral-50 ': disabled,
              })}
              onClick={() => {
                // TODO - Property 'current' does not exist on type '(instance: HTMLInputElement | null) => void'
                targerRef.current?.focus();
              }}
            >
              {getIcon(
                variant,
                cx('h-4', 'w-4', 'group-focus-within:text-brand-100')
              )}
            </div>
          )}
        </div>
        {showErrors && error_messages && (
          <div className={cx('py-2')}>
            {error_messages.map((m, k) => (
              <ErrorLine key={`${m}-${k}`} message={m} />
            ))}
          </div>
        )}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
